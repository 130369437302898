'use strict';

function depVarController($scope, $window, mapService) {

    var appWindow = angular.element($window);

    appWindow.bind('resize', function () {
        resizeHeader();
    });

    $scope.muteVideo = function () {
        var muteButton = document.getElementById('muteButton')
        var video = document.getElementById('headerVideo');
        if (video.muted == false) {
            // Mute the video
            video.muted = true;
            // Update the button text
            muteButton.setAttribute('aria-label', 'Désactiver le son');
            muteButton.classList.toggle("mute");
        } else {
            // Unmute the video
            video.muted = false;
            // Update the button text
            muteButton.setAttribute('aria-label', 'Activer le son');
            muteButton.classList.toggle("mute");
        }
    };

    $scope.pauseVideo = function() {
        var video = document.getElementById('headerVideo');
        video.paused?video.play():video.pause();
    };


    $scope.maximizeCard = function() {
        if(!$scope.foldResults) {
            $scope.resultsPaneToggle();
        }
        if(jQuery(".leaflet-control-fullscreen-button")) {
            jQuery(".leaflet-control-fullscreen-button")[0].click();
        }
    };

    $scope.minimizeCard = function() {
        if($scope.foldResults) {
            $scope.resultsPaneToggle();
        }
    };

    function resizeHeader() {
        var originalVideoWidth = 1920;
        var originalVideoHeight = 1080;
        var screenWidth = window.top.innerWidth;
        var screenHeight = window.top.innerHeight;
        var screenHeaderHeight = (screenHeight / 2) + 50;
        var headerHeight = (screenWidth * originalVideoHeight) /  originalVideoWidth;
        if (headerHeight > screenHeaderHeight) {
            headerHeight = screenHeaderHeight;
        }
        var headerElem = document.getElementById('var-header');
        var videoElem = document.getElementById('homeVideo');
        var videoPlayer = document.getElementById('headerVideo');
        if(headerElem !=undefined &&  headerElem!=null) {
        headerElem.style.height = headerHeight + "px";
        }
        if(videoElem !=undefined &&  videoElem!=null) {
            videoElem.style.height = headerHeight + "px";
        }
        if(videoPlayer !=undefined &&  videoPlayer!=null) {
            videoPlayer.style.height = headerHeight + "px";
        }
    }

    function init() {
        resizeHeader();
    }
    init();
}

angular.module('rando.custom').controller('depVarController', depVarController);
